import React, { useCallback, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import InputAdornment from '@material-ui/core/InputAdornment';
import LinearProgress from '@material-ui/core/LinearProgress';

import Autocomplete from '@material-ui/lab/Autocomplete';

import { Grid, Box } from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';
import ReportProblemRoundedIcon from '@material-ui/icons/ReportProblemRounded';

import { dispatch, store } from '../../config/store';

import { SquadReadRes } from '../../config/interfaces/Squads';
import {
  SprintDashboardSprintRes,
  SprintReadRes,
} from '../../config/interfaces/Sprints';
import { UserData } from '../../config/interfaces/Users';

import { ProjectData } from '../../config/models/projects';

import useDebounce from '../../hooks/useDebounce';

import PDSDialog from '../../components/Dialog';
import BreadCrumbBar from '../../components/BreadCrumbBar';

import Board from './components/Board';
import SquadBacklog from './components/SquadBacklog';
import SquadSettings from './components/SquadSettings';

import { useStyles } from './styles';

interface TabPanelProps {
  children: React.ReactNode;
  value: number;
  index: number;
}

interface FilterProps {
  userId?: number;
  projectId?: number;
}

const SquadDetails: React.FC = () => {
  const classes = useStyles();

  const [tabValue, setTabValue] = useState(0);

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [resultDialogOpen, setResultDialogOpen] = useState(false);
  const [issuesProgress, setIssueProgress] = useState(0);

  // Filters data
  const [projects, setProjects] = useState<ProjectData[]>([]);
  const [users, setUsers] = useState<UserData[]>([]);

  const [currentSquad, setCurrentSquad] = useState<SquadReadRes>();
  const [currentSprint, setCurrentSprint] = useState<SprintReadRes>();
  const [currentDashboardSprint, setCurrentDashboardSprint] =
    useState<SprintDashboardSprintRes>();
  const [columns, setColumns] = useState<SprintReadRes['sprint']['columns']>(
    [] as SprintReadRes['sprint']['columns'],
  );

  const [filteredColumns, setFilteredColumns] = useState(
    [] as SprintReadRes['sprint']['columns'],
  );

  const [filters, setFilters] = useState<FilterProps>({} as FilterProps);

  const [projectQuery, setProjectQuery] = useState('');
  const debouncedProjectQuery = useDebounce<string>(projectQuery, 500);

  const [backlogTasks, setBacklogTasks] = useState<SprintReadRes['backlog']>();
  const [sprintTasks, setSprintTasks] =
    useState<SprintReadRes['sprint']['columns'][0]['tasks']>();
  const [usersSquad, setUsersSquad] = useState<SquadReadRes['users']>();

  const { squad: strSquad } = useParams<{ squad: string }>();
  const squadId = +strSquad;

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    fetchTasks().then(() => {
      setTabValue(newValue);
    });
  };

  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index } = props;

    return <div>{value === index && <div>{children}</div>}</div>;
  };

  const handleConfirmationDialog = () => {
    setConfirmationDialogOpen(oldState => !oldState);
  };

  const handleResultDialog = () => {
    setResultDialogOpen(oldState => !oldState);
  };

  const finishSprintOnClick = async () => {
    if (!currentSprint || !currentDashboardSprint || !currentSquad) {
      return;
    }
    const { id } = currentSquad.sprint;
    if (currentSquad?.sprint) {
      await dispatch.Sprints.dashboardSprintAsync({ params: { id } }).then(
        () => {
          setCurrentDashboardSprint(store.getState().Sprints.dashboardSprint);
        },
      );
    }
    setConfirmationDialogOpen(oldState => !oldState);
    setIssueProgress(
      currentDashboardSprint?.dashboard.tasks_finished /
        currentDashboardSprint?.dashboard.total_task /
        100,
    );
    setResultDialogOpen(oldState => !oldState);
    await dispatch.Sprints.finishSprintAsync({
      id: currentSprint?.sprint.id,
    });
  };

  const content = (
    <>
      <p style={{ margin: '10px' }}>
        Você está prestes a finalizar a sprint, está ação não pode ser desfeita.
        Tem certeza que deseja continuar?
      </p>
      <Grid
        container
        spacing={1}
        justify="flex-end"
        style={{ marginTop: '50px' }}
      >
        <Grid item xs={2}>
          <Button variant="text" onClick={() => handleConfirmationDialog()}>
            Cancelar
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="contained"
            onClick={() => finishSprintOnClick()}
            className={classes.redButton}
          >
            Finalizar
          </Button>
        </Grid>
      </Grid>
    </>
  );

  const handleFiltering = useCallback(() => {
    setFilteredColumns(
      columns.map(
        column =>
          ({
            ...column,
            tasks: column.tasks.filter(task => {
              if (filters.userId && filters.userId !== task.user_assignee_id) {
                return false;
              }

              if (filters.projectId && filters.projectId !== task.project_id) {
                return false;
              }

              if (
                debouncedProjectQuery &&
                task.project.name
                  .toLowerCase()
                  .indexOf(projectQuery.toLowerCase()) === -1
              ) {
                return false;
              }
              return true;
            }),
          } as SprintReadRes['sprint']['columns'][0]),
      ),
    );
  }, [
    columns,
    debouncedProjectQuery,
    filters.projectId,
    filters.userId,
    projectQuery,
  ]);

  const fetchColumns = async () => {
    if (!currentSquad) {
      return;
    }

    const { id } = currentSquad.sprint;

    await dispatch.Sprints.readSprintAsync({ id }).then(() => {
      setColumns(store.getState().Sprints.sprint.sprint.columns);

      return Promise.resolve();
    });
  };

  const fetchTasks = async () => {
    if (!currentSquad) {
      return;
    }

    const { id } = currentSquad.sprint;

    dispatch.Sprints.readSprintAsync({ id }).then(() => {
      setCurrentSprint(store.getState().Sprints.sprint);
      setColumns(store.getState().Sprints.sprint.sprint.columns);
      setFilteredColumns(store.getState().Sprints.sprint.sprint.columns);
      setBacklogTasks(store.getState().Sprints.sprint.backlog);
      setSprintTasks(
        store
          .getState()
          .Sprints.sprint.sprint.columns.map(c => c.tasks)
          .flat(),
      );
    });
  };

  useEffect(() => {
    handleFiltering();
  }, [filters, debouncedProjectQuery, columns, handleFiltering]);

  useEffect(() => {
    if (!currentSquad) {
      return;
    }

    const { id } = currentSquad.sprint;

    dispatch.Sprints.readSprintAsync({ id }).then(() => {
      const currentStore = store.getState();

      setCurrentSprint(currentStore.Sprints.sprint);
      setColumns(currentStore.Sprints.sprint.sprint.columns);
      setFilteredColumns(currentStore.Sprints.sprint.sprint.columns);
      setBacklogTasks(currentStore.Sprints.sprint.backlog);
      setSprintTasks(
        currentStore.Sprints.sprint.sprint.columns.map(c => c.tasks).flat(),
      );
      setUsersSquad(currentStore.Squads.squad?.users);
    });

    dispatch.Sprints.dashboardSprintAsync({ params: { id } }).then(() => {
      setCurrentDashboardSprint(store.getState().Sprints.dashboardSprint);
    });
  }, [currentSquad]);

  useEffect(() => {
    dispatch.Squads.read({ id: squadId }).then(() => {
      setCurrentSquad(store.getState().Squads.squad);
    });

    dispatch.Projects.readAll().then(() => {
      setProjects(store.getState().Projects);
    });

    dispatch.Users.readAll().then(() => {
      setUsers(store.getState().Users.users);
    });
  }, [squadId]);

  return (
    <>
      <div className={classes.header}>
        <BreadCrumbBar
          data={[
            { label: 'Squads', path: '/squads' },
            { label: currentSquad?.name ?? '' },
          ]}
        />
      </div>
      <div className={classes.contentContainer}>
        <div className={classes.options}>
          <Tabs
            className={classes.tabs}
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab
              className={classes.tabName}
              label={`Board: sprint ${currentSquad?.amount_sprint ?? ''}`}
            />
            <Tab className={classes.tabName} label="Backlog" />
            <Tab className={classes.tabName} label="Settings" />
          </Tabs>

          <div className={classes.filters}>
            {tabValue !== 2 && (
              <>
                <TextField
                  className={classes.searchBar}
                  id="search-project"
                  label="Procurar Projeto"
                  variant="outlined"
                  size="small"
                  value={projectQuery}
                  onChange={e => setProjectQuery(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon style={{ opacity: '0.2' }} />
                      </InputAdornment>
                    ),
                  }}
                />
                <Autocomplete
                  className={classes.filterSelector}
                  id="users"
                  options={users}
                  getOptionLabel={user => user.name}
                  size="small"
                  renderInput={params => (
                    <TextField {...params} label="Membro" variant="outlined" />
                  )}
                  onChange={(_, data) => {
                    if (data) {
                      setFilters({ ...filters, userId: data.id });
                    }
                  }}
                  onInputChange={(_, value) => {
                    if (value === '') {
                      setFilters({ ...filters, userId: undefined });
                    }
                  }}
                />
                <Autocomplete
                  className={classes.filterSelector}
                  id="projects"
                  options={projects}
                  getOptionLabel={project => project.name}
                  size="small"
                  renderInput={params => (
                    <TextField {...params} label="Projeto" variant="outlined" />
                  )}
                  onChange={(_, data) => {
                    if (data) {
                      setFilters({ ...filters, projectId: data.id });
                    }
                  }}
                  onInputChange={(_, value) => {
                    if (value === '') {
                      setFilters({ ...filters, projectId: undefined });
                    }
                  }}
                />
              </>
            )}
            <Button
              className={classes.button}
              size="small"
              variant="contained"
              color="primary"
              onClick={() => handleConfirmationDialog()}
            >
              Finalizar Sprint
            </Button>
          </div>
        </div>
        <TabPanel value={tabValue} index={0}>
          <Board
            columnsProp={filteredColumns}
            squadId={squadId}
            fetchColumns={fetchColumns}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <SquadBacklog
            backlogTasks={backlogTasks}
            sprintTasks={sprintTasks}
            usersSquad={usersSquad}
            fetchTasks={fetchTasks}
            squadId={squadId}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <SquadSettings />
        </TabPanel>
      </div>

      <PDSDialog
        title="Finalizar sprint?"
        content={content}
        open={confirmationDialogOpen}
        showActions={false}
        handleClose={handleConfirmationDialog}
        confirm={handleConfirmationDialog}
        buttonText="Finalizar"
        titleIcon={
          <ReportProblemRoundedIcon
            className={classes.iconWarning}
            fontSize="large"
          />
        }
      />

      <Dialog
        open={resultDialogOpen}
        onClose={handleResultDialog}
        maxWidth="xs"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className={classes.title}>
          <IconButton
            aria-label="close"
            onClick={() => handleResultDialog()}
            className={classes.iconClose}
          >
            <CloseIcon />
          </IconButton>
          Resumo da Sprint
        </DialogTitle>
        <DialogContent className={classes.body}>
          <Box style={{ width: '100%' }}>
            <LinearProgress
              variant="determinate"
              value={issuesProgress}
              className={classes.progress}
            />
          </Box>
          <span>{`${
            currentDashboardSprint?.dashboard?.tasks_finished ?? 0
          } de ${
            currentDashboardSprint?.dashboard?.total_task ?? 0
          } foram concluídas!`}</span>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => handleResultDialog()}
            className={classes.greenButton}
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SquadDetails;
