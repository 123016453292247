import { makeStyles, Box } from '@material-ui/core';
import styled from 'styled-components';
import { Theme } from '@material-ui/core/styles';

interface CustomBoxProps {
  active: string;
  theme: Theme;
}

export const CustomBox = styled(Box)<CustomBoxProps>`
  width: 80%;
  height: 3rem;
  padding: 0px 25px;

  border-radius: 4px;
  border-bottom: 1px solid #e5e5e5;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  color: #373737;

  ${({ active }) => {
    let color;
    if (active === 'true') {
      color = '#ffffff';
    }
    return `
    color: ${color};
 `;
  }};

  background-color: #ffff;

  ${({ active }) => {
    let color;
    if (active === 'true') {
      color = '#263579';
    }
    return `
    background-color: ${color};
 `;
  }};

  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);

  cursor: pointer;
`;

export const useStyles = makeStyles(theme => ({
  requirementNumber: {
    fontWeight: 'bold',
    fontSize: '20px;',
    color: 'inherit',
  },
  requirementTitle: {
    fontSize: '18px',
    color: 'inherit',
    fontWeight: 'normal',
    alignItems: 'center',
  },
  textRoot: {
    flex: '0.2',
  },
  contentRoot: {
    flex: '1 ',
  },

  list: {
    listStyleType: 'none',
    paddingLeft: '2px',
  },

  listItem: {
    height: '100%',
  },

  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));
