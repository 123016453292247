/* eslint-disable react/no-array-index-key */
/* eslint-disable operator-linebreak */
import React, { useState, ChangeEvent } from 'react';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import Toolbar from '@material-ui/core/Toolbar';
import Checkbox from '@material-ui/core/Checkbox';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import TableContainer from '@material-ui/core/TableContainer';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { useStyles } from './styles';

export interface Action<Callback extends Function> {
  icon: JSX.Element;
  callback?: Callback;
}

export type ToolbarAction = Action<() => void>;
export type RowAction = Action<(id: number) => void>;

export interface RowData {
  id: number;
  [key: string]: any;
}

export interface ColumnData {
  id: string;
  label: string;
  sortable?: boolean;
  numeric?: boolean;
}

interface TableProps {
  columns: ColumnData[];
  rows: RowData[];
  selectable?: boolean;
  rowsPerPageOptions?: number[];
  pagination?: boolean;
  title?: string | JSX.Element;
  showToolbar?: boolean;
  dense?: boolean;
  actions?: RowAction[];
  toolbarActions?: ToolbarAction[];
  onClick?: (id: number) => void;
  onPageChange?: (_: unknown, page: number) => void;
  onPaginationSizeChange?: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  pageNumber?: number;
  pageSize?: number;
  count?: number;
}

type Order = 'asc' | 'desc';

const PDSTable: React.FC<TableProps> = ({
  columns,
  rows,
  selectable = false,
  rowsPerPageOptions = [5, 10, 25],
  pagination = true,
  title,
  showToolbar = false,
  dense: defaultDense = true,
  actions,
  toolbarActions: mainActions,
  onClick,
  onPageChange,
  onPaginationSizeChange,
  pageNumber,
  pageSize,
  count,
}) => {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<string>();
  const [dense, setDense] = useState(defaultDense);
  const [selected, setSelected] = useState<number[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  /* Sorting */
  const createSortHandler = (id: string) => () => {
    const isAsc = orderBy === id && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(id);
  };

  function stableSort<T extends Record<string, any>>(array: T[]) {
    const withIndex = array.map((el, index) => [el, index] as [T, number]);
    const m = order === 'desc' ? 1 : -1;

    withIndex.sort(([a, ai], [b, bi]) => {
      if (orderBy) {
        if (a[orderBy] > b[orderBy]) return -1 * m;
        if (a[orderBy] < b[orderBy]) return 1 * m;
      }
      return ai - bi;
    });

    return withIndex.map(([el]) => el);
  }

  /* Pagination */
  const handleSetPage = (_: unknown, p: number) => setPage(p);

  const handleSetRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    const pages = Math.floor(rows.length / +event.target.value);
    if (page > pages) setPage(0);
  };

  /* Selection */
  const handleSelectAll = ({
    target: { checked },
  }: ChangeEvent<HTMLInputElement>) => {
    setSelected(checked ? rows.map(row => row.id) : []);
  };

  const handleSelect = (
    { target: { checked } }: ChangeEvent<HTMLInputElement>,
    id: number,
  ) => {
    if (checked) {
      setSelected([...selected, id]);
    } else {
      setSelected(selected.filter(e => e !== id));
    }
  };

  /* Dense Switch */
  const handleToggleDense = () => setDense(!dense);

  const classes = useStyles();

  return (
    <Paper>
      {showToolbar && (
        <Toolbar>
          <Box className={classes.title}>
            {selected.length > 0 ? (
              <Typography variant="subtitle1">
                {selected.length} selected
              </Typography>
            ) : typeof title === 'string' ? (
              <Typography variant="h6">{title}</Typography>
            ) : (
              title
            )}
          </Box>
          {mainActions?.map(({ icon, callback }, index) => (
            <IconButton
              key={index}
              onClick={callback ? () => callback() : undefined}
              disabled={!callback}
            >
              {icon}
            </IconButton>
          ))}
        </Toolbar>
      )}
      <TableContainer>
        <Table style={{ width: '100%' }} size={dense ? 'small' : 'medium'}>
          <TableHead>
            <TableRow>
              {selectable && (
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selected.length === rows.length}
                    indeterminate={
                      selected.length > 0 && selected.length < rows.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
              )}
              {columns.map(({ id, numeric, label, sortable }) => (
                <TableCell
                  key={`header-${id}`}
                  align={numeric ? 'right' : 'left'}
                >
                  {!sortable ? (
                    label
                  ) : (
                    <TableSortLabel
                      active={orderBy === id}
                      direction={orderBy === id ? order : 'asc'}
                      onClick={createSortHandler(id)}
                    >
                      {label}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
              {actions && <TableCell />}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(rows)
              .slice(
                pagination && !pageSize ? page * rowsPerPage : 0,
                pagination && !pageSize
                  ? (page + 1) * rowsPerPage
                  : rows.length,
              )
              .map(row => (
                <TableRow
                  key={row.id}
                  selected={selected.includes(row.id)}
                  hover={!!onClick}
                  className={onClick ? classes.pointer : undefined}
                  onClick={onClick && (() => onClick(row.id))}
                >
                  {selectable && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selected.includes(row.id)}
                        onChange={e => handleSelect(e, row.id)}
                      />
                    </TableCell>
                  )}

                  {columns.map(({ id, numeric }, coli) => (
                    <TableCell
                      key={`row-${row.id}-${id ?? coli}`}
                      align={numeric ? 'right' : 'left'}
                    >
                      {row[id]}
                    </TableCell>
                  ))}

                  {actions && (
                    <TableCell align="right">
                      {actions.map(({ icon, callback }, index) => (
                        <IconButton
                          key={index}
                          size={dense ? 'small' : 'medium'}
                          onClick={
                            callback
                              ? e => {
                                  callback(row.id);
                                  e.stopPropagation();
                                }
                              : undefined
                          }
                          disabled={!callback}
                          className={classes.actions}
                        >
                          {icon}
                        </IconButton>
                      ))}
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box className={classes.paginationActions}>
        <Box flexGrow="1">
          <FormControlLabel
            label={<Typography variant="body2">Dense</Typography>}
            control={<Switch checked={dense} onChange={handleToggleDense} />}
          />
        </Box>
        {pagination && (
          <TablePagination
            component={Box}
            rowsPerPageOptions={rowsPerPageOptions}
            count={count || rows.length}
            page={pageNumber ? pageNumber - 1 : page}
            onPageChange={onPageChange || handleSetPage}
            rowsPerPage={pageSize || rowsPerPage}
            onRowsPerPageChange={onPaginationSizeChange || handleSetRowsPerPage}
          />
        )}
      </Box>
    </Paper>
  );
};

export default PDSTable;
