import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableContainer from '@material-ui/core/TableContainer';

import { useSelector } from 'react-redux';

import CloseIcon from '@material-ui/icons/Close';

import TimerIcon from '@material-ui/icons/Timer';

import moment from 'moment';

import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

import { useStyles } from './styles';
import {
  UsersEntity,
  SquadGetUsersHoursRes,
} from '../../../../../../config/interfaces/Squads';
import { RootState } from '../../../../../../config/store';

interface MemberInfoDialogProps {
  open: boolean;
  handleClose: () => void;
  fullWidth?: boolean;
  user: UsersEntity;
  userHours: SquadGetUsersHoursRes[0];
  squadId?: number;
}

const MemberInfoDialog: React.FC<MemberInfoDialogProps> = ({
  open = false,
  handleClose,
  fullWidth = false,
  user,
  userHours,
}) => {
  const classes = useStyles();

  const squad = useSelector((state: RootState) => state.Squads.squad);
  const tasks = useSelector((state: RootState) =>
    state.Sprints.sprint.sprint.columns
      .map(column => column.tasks)
      .flat()
      .filter(task => task.user_assignee_id === user.id),
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={fullWidth}
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <div className={classes.dialogTitleWrapper}>
          <span className={classes.dialogTitle}>Horas ocupadas do usuário</span>
          <Button onClick={handleClose} style={{ marginLeft: '15px' }}>
            <CloseIcon />
          </Button>
        </div>
      </DialogTitle>

      <TableContainer className={classes.tableContainer}>
        <Table key={squad?.name} size="small" style={{ marginBottom: '10px' }}>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '33.33%' }} align="left">
                {squad?.name}
              </TableCell>
              <TableCell style={{ width: '33.33%' }} align="left">
                <div className={classes.divTableCell}>
                  <TimerIcon style={{ width: '20px', marginRight: '5px' }} />
                  {userHours.user_total_hours_left_squad}
                </div>
              </TableCell>
              <TableCell style={{ width: '33.33%' }} align="right">
                <div className={classes.divTableCell}>
                  <CalendarTodayIcon
                    style={{ width: '18px', marginRight: '5px' }}
                  />
                  {moment(squad?.sprint.expected_end_date).format('DD/MM/YYYY')}
                </div>
              </TableCell>
            </TableRow>
          </TableHead>

          {tasks.map(task => (
            <TableBody>
              <TableRow>
                <TableCell className={classes.tableCellBody} align="left">
                  {task.name}
                </TableCell>
                <TableCell className={classes.tableCellBody} align="left">
                  <div className={classes.divTableCell}>
                    <TimerIcon style={{ width: '20px', marginRight: '5px' }} />
                    {task.time_estimated}
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          ))}
        </Table>
      </TableContainer>

      <DialogActions>
        <div className={classes.formButtonsContainer}>
          <Button
            style={{ backgroundColor: '#263579', color: '#fff' }}
            variant="contained"
            type="submit"
            onClick={handleClose}
            className={classes.buttonCreate}
          >
            Fechar
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default MemberInfoDialog;
