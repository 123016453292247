import React, { useEffect, useState } from 'react';

import Menu from '@material-ui/core/Menu';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import ScheduleIcon from '@material-ui/icons/Schedule';

import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';

import { useDispatch, useSelector } from 'react-redux';

import Dialog from '../../../../components/Dialog';
import TaskContent from '../../../../components/TaskContent';
import MemberInfoDialog from './components/MemberInfoDialog/index';
import PDSDeleteTaskDialog from '../../../../components/DeleteTaskDialog';

import { SprintReadRes } from '../../../../config/interfaces/Sprints';
import {
  SquadReadRes,
  UsersEntity,
  SquadGetUsersHoursRes,
} from '../../../../config/interfaces/Squads';

import { Dispatch, RootState } from '../../../../config/store';

import BugType from '../../../../assets/SelectType/BugType.svg';
import TaskType from '../../../../assets/SelectType/TaskType.svg';
import StoryType from '../../../../assets/SelectType/StoryType.svg';

import { MemberCard } from './components/MemberCard/index';

import { useStyles } from './styles';

const columnsArray = [
  {
    id: '1',
    name: 'Backlog',
  },
  {
    id: '2',
    name: 'Sprint',
  },
];

const types = [
  { id: 1, type: 'bug', img: BugType, alt: 'BugType' },
  { id: 2, type: 'task', img: TaskType, alt: 'TaskType' },
  { id: 3, type: 'story', img: StoryType, alt: 'StoryType' },
];

export interface TaskProps {
  id: string;
  content: string;
  author: string;
  status: string;
  difficulty: string;
}

interface SquadBacklogProps {
  backlogTasks?: SprintReadRes['backlog'];
  sprintTasks?: SprintReadRes['sprint']['columns'][0]['tasks'];
  usersSquad?: SquadReadRes['users'];
  fetchTasks?: () => void;
  squadId: number;
}

const r = Math.round(Math.random() * 200);
const g = Math.round(Math.random() * 200);
const b = Math.round(Math.random() * 200);

const SquadBacklog: React.FC<SquadBacklogProps> = ({
  backlogTasks,
  sprintTasks,
  usersSquad,
  fetchTasks,
  squadId,
}: SquadBacklogProps) => {
  const dispatch = useDispatch<Dispatch>();

  const currentSprint = useSelector(
    (state: RootState) => state.Squads.squad?.sprint.id,
  );

  const users = useSelector((state: RootState) => state.Users.users);

  const usersHours = useSelector((state: RootState) => state.Squads.usersHours);

  const [currentIssueId, setCurrentIssueId] = useState<number>();

  const [deleteDialogActive, setDeleteDialogActive] = useState(false);

  const [currentTask, setCurrentTask] =
    useState<SprintReadRes['sprint']['columns'][0]['tasks'][0]>();
  const [taskMenuAnchor, setTaskMenuAnchor] = useState<null | HTMLElement>(
    null,
  );

  const [modalMemberInfoActive, setModalMemberInfoActive] = useState(false);

  const [modalCreateTaskActive, setModalCreateTaskActive] = useState(false);

  const [backlogCount, setBacklogCount] = useState(backlogTasks?.length ?? 0);
  const [sprintCount, setSprintCount] = useState(sprintTasks?.length ?? 0);

  const [currentUser, setCurrentUser] = useState<UsersEntity>();

  const currentSprintId = useSelector(
    (state: RootState) => state.Squads.squad?.sprint.id,
  );

  const classes = useStyles();

  const handleDeleteDialog = () => {
    setDeleteDialogActive(!deleteDialogActive);
  };

  const handleMemberInfoDialog = (user?: UsersEntity) => {
    if (user !== undefined) {
      setCurrentUser(user);
    }
    setModalMemberInfoActive(!modalMemberInfoActive);
  };

  const onDragEnd = async (result: DropResult) => {
    // Card with no destination
    if (!result.destination) return;

    const { source, destination } = result;

    // Card moved to another column
    if (source.droppableId !== destination.droppableId) {
      if (!backlogTasks || !sprintTasks || !currentSprint || !fetchTasks)
        return;

      const toSprint = destination.droppableId === '2';

      const sourceIndex = source.index;

      const taskId = toSprint
        ? backlogTasks[sourceIndex].id
        : sprintTasks[sourceIndex].id;

      const columnId = toSprint
        ? backlogTasks[sourceIndex].column_id
        : sprintTasks[sourceIndex].column_id;

      const payload = {
        id: taskId,
        change_to_sprint: toSprint ? currentSprint : undefined,
        change_to_column: columnId,
        tasks_in_destination_column: toSprint
          ? [
              taskId,
              ...sprintTasks
                .filter(task => task.column_id === columnId)
                .map(task => task.id),
            ]
          : [taskId, ...backlogTasks.map(task => task.id)],
      };

      if (toSprint) {
        sprintTasks.push(backlogTasks[sourceIndex]);
        backlogTasks.splice(sourceIndex, 1);
      } else {
        backlogTasks.push(sprintTasks[sourceIndex]);
        sprintTasks.splice(sourceIndex, 1);
      }

      setBacklogCount(backlogTasks.length);
      setSprintCount(sprintTasks.length);

      dispatch.Tasks.updatePosition(payload);
    }
  };

  const handleDialogTask = () => {
    if (fetchTasks && modalCreateTaskActive) {
      setCurrentIssueId(undefined);
      fetchTasks();
    }

    setModalCreateTaskActive(!modalCreateTaskActive);
  };

  const handleTimeMask = (time: number) => {
    const hour = time.toString().slice(0, 2);
    return `${hour}:00`;
  };

  const handleUserInitialMask = (userID: number) => {
    const userName = users.find(user => user.id === userID)?.name ?? '';

    const initials = userName
      .split(' ')
      .reduce((acc, cur) => acc + cur[0], '')
      .toUpperCase();
    return `${initials}`;
  };

  const handleColorMask = (id: number) => {
    if (id % 2 === 0) {
      id *= 10;
    } else {
      id *= 20;
    }

    return `rgba(${r - id}, ${g - id}, ${b - id}, 1)`;
  };

  const openTaskMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setTaskMenuAnchor(event.currentTarget);
  };

  const closeTaskMenu = () => {
    setTaskMenuAnchor(null);
  };

  const handleDeleteTask = () => {
    if (!currentTask || !fetchTasks) {
      return;
    }

    dispatch.Tasks.remove({ id: currentTask.id }).then(() => {
      fetchTasks();
    });
  };

  useEffect(() => {
    if (!currentSprint) return;

    dispatch.Sprints.readSprintAsync({ id: currentSprint });
  }, [currentSprint, dispatch]);

  useEffect(() => {
    dispatch.Squads.getUsersHours({ squad_id: squadId });
  }, [dispatch, squadId]);

  return (
    <>
      <Dialog
        title=""
        content={
          <TaskContent
            squadId={squadId}
            closeModal={handleDialogTask}
            {...(currentIssueId && { issueId: currentIssueId })}
            {...(currentSprintId && { sprintId: true })}
          />
        }
        open={modalCreateTaskActive}
        handleClose={handleDialogTask}
        classDialogTitle={classes.modalTitle}
        showActions={false}
        fullWidth
        maxWidth="md"
      />

      <PDSDeleteTaskDialog
        open={deleteDialogActive}
        fullWidth={false}
        handleClose={handleDeleteDialog}
        confirm={handleDeleteTask}
        task={currentTask}
      />

      <MemberInfoDialog
        open={modalMemberInfoActive}
        fullWidth
        handleClose={handleMemberInfoDialog}
        user={currentUser ?? ({} as UsersEntity)}
        userHours={
          usersHours?.find(u => u.user === currentUser?.name) ??
          ({} as SquadGetUsersHoursRes[0])
        }
        squadId={+squadId}
      />

      <div className={classes.dragDropContainer}>
        <DragDropContext onDragEnd={result => onDragEnd(result)}>
          {columnsArray.map(column => (
            <>
              <div className={classes.column} key={column.id}>
                <h2 className={classes.title}>
                  {column.name}
                  <span className={classes.issues}>
                    {sprintTasks && backlogTasks && column.id === '1'
                      ? `${backlogCount} issues`
                      : `${sprintCount} issues`}
                  </span>
                </h2>
                <Droppable droppableId={column.id} type="column">
                  {dropProvided => (
                    <div
                      className={classes.droppable}
                      ref={dropProvided.innerRef}
                      {...dropProvided.droppableProps}
                    >
                      {column.id === '1' && backlogTasks
                        ? backlogTasks.map((item, index) => (
                            <Draggable
                              key={item.id}
                              draggableId={(item.id ?? '').toString()}
                              index={index}
                            >
                              {dragProvided => (
                                <div
                                  aria-hidden
                                  className={classes.task}
                                  {...dragProvided.draggableProps}
                                  ref={dragProvided.innerRef}
                                  {...dragProvided.dragHandleProps}
                                  onClick={() => {
                                    setCurrentIssueId(item.id);
                                    handleDialogTask();
                                  }}
                                >
                                  <div className={classes.taskLeft}>
                                    <div className={classes.issue}>
                                      <img
                                        src={
                                          item.type === 'bug'
                                            ? types[0].img
                                            : item.type === 'task'
                                            ? types[1].img
                                            : types[2].img
                                        }
                                        alt={
                                          item.type === 'bug'
                                            ? types[0].alt
                                            : item.type === 'task'
                                            ? types[1].alt
                                            : types[2].alt
                                        }
                                      />
                                    </div>
                                    <div>
                                      <span className={classes.taskDescription}>
                                        {item.name}
                                      </span>
                                    </div>
                                  </div>
                                  <div className={classes.taskRight}>
                                    <span className={classes.taskStatus}>
                                      Em progesso
                                    </span>
                                    <span className={classes.taskDifficulty}>
                                      <div className={classes.estimate}>
                                        <ScheduleIcon fontSize="small" />
                                        <span className={classes.time}>
                                          {handleTimeMask(item.time_estimated!)}
                                        </span>
                                      </div>
                                    </span>
                                    <Avatar
                                      style={{
                                        backgroundColor: `${handleColorMask(
                                          item.id,
                                        )}`,
                                        fontSize: '15px',
                                      }}
                                    >
                                      {handleUserInitialMask(
                                        item.user_assignee_id,
                                      )}
                                    </Avatar>
                                    <IconButton
                                      aria-controls="task-actions"
                                      aria-haspopup="true"
                                      onClick={e => {
                                        openTaskMenu(e);
                                        setCurrentTask(item);
                                      }}
                                    >
                                      <MoreVertIcon fontSize="small" />
                                    </IconButton>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))
                        : sprintTasks &&
                          sprintTasks?.map((item, index) => (
                            <Draggable
                              key={item.id}
                              draggableId={(item.id ?? '').toString()}
                              index={index}
                            >
                              {dragProvided => (
                                <div
                                  aria-hidden
                                  className={classes.task}
                                  {...dragProvided.draggableProps}
                                  ref={dragProvided.innerRef}
                                  {...dragProvided.dragHandleProps}
                                  onClick={() => {
                                    setCurrentIssueId(item.id);
                                    handleDialogTask();
                                  }}
                                >
                                  <div className={classes.taskLeft}>
                                    <div className={classes.issue}>
                                      <img
                                        src={
                                          item.type === 'bug'
                                            ? types[0].img
                                            : item.type === 'task'
                                            ? types[1].img
                                            : types[2].img
                                        }
                                        alt={
                                          item.type === 'bug'
                                            ? types[0].alt
                                            : item.type === 'task'
                                            ? types[1].alt
                                            : types[2].alt
                                        }
                                      />
                                      <span className={classes.taskID}>
                                        IS-{currentSprintId}
                                      </span>
                                    </div>
                                    <div>
                                      <span className={classes.taskDescription}>
                                        {item.name}
                                      </span>
                                    </div>
                                  </div>
                                  <div className={classes.taskRight}>
                                    <span className={classes.taskStatus}>
                                      Em progesso
                                    </span>
                                    <span className={classes.taskDifficulty}>
                                      <div className={classes.estimate}>
                                        <ScheduleIcon fontSize="small" />
                                        <span className={classes.time}>
                                          {handleTimeMask(item.time_estimated!)}
                                        </span>
                                      </div>
                                    </span>
                                    <Avatar
                                      style={{
                                        backgroundColor: `${handleColorMask(
                                          item.id,
                                        )}`,
                                        fontSize: '15px',
                                      }}
                                    >
                                      {handleUserInitialMask(
                                        item.user_assignee_id,
                                      )}
                                    </Avatar>
                                    <IconButton
                                      aria-controls="task-actions"
                                      aria-haspopup="true"
                                      onClick={e => {
                                        openTaskMenu(e);
                                        setCurrentTask(item);
                                      }}
                                    >
                                      <MoreVertIcon fontSize="small" />
                                    </IconButton>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                      {dropProvided.placeholder}
                    </div>
                  )}
                </Droppable>
                <div className={classes.createTaskContainer}>
                  <Button
                    className={classes.createTask}
                    onClick={() => handleDialogTask()}
                    variant="contained"
                  >
                    + Criar issue
                  </Button>
                </div>
              </div>
            </>
          ))}
          <div className={classes.membersColumn}>
            <h2 className={classes.title}>
              MEMBROS
              <span className={classes.issues}>
                {usersSquad?.length} MEMBROS
              </span>
            </h2>
            <div className={classes.memberBox}>
              {usersSquad?.map(user => {
                const userHours = usersHours?.find(u => u.user === user.name);

                return (
                  <MenuItem
                    onClick={() => {
                      handleMemberInfoDialog(user);
                    }}
                  >
                    <MemberCard
                      name={user.name}
                      allocatedHours={userHours?.user_total_hours_busy ?? 0}
                      totalHours={userHours?.user_total_hours_left_squad ?? 0}
                    />
                  </MenuItem>
                );
              })}
            </div>
          </div>
        </DragDropContext>
      </div>
      <Menu
        id="task-actions"
        anchorEl={taskMenuAnchor}
        keepMounted
        open={Boolean(taskMenuAnchor)}
        onClose={closeTaskMenu}
      >
        <span className={classes.taskMenuTitle}>Ações</span>
        <MenuItem className={classes.taskMenuItem} onClick={closeTaskMenu}>
          Copiar link
        </MenuItem>
        <MenuItem
          className={classes.taskMenuItemApagar}
          onClick={() => {
            closeTaskMenu();
            handleDeleteDialog();
          }}
        >
          Apagar
        </MenuItem>
      </Menu>
    </>
  );
};

SquadBacklog.defaultProps = {
  backlogTasks: [] as SprintReadRes['backlog'],
  sprintTasks: [] as SprintReadRes['sprint']['columns'][0]['tasks'],
  usersSquad: [] as SquadReadRes['users'],
  fetchTasks: () => {},
};

export default SquadBacklog;
